import React from "react";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import ScriptSearch from "./ScriptSearch";
import TableHeader from "./TableHeader";
import WatchlistCard from "./WatchlistCard";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 99;

export default function Watchlist() {
  const [loading, setLoading] = React.useState(false);
  const [allScripts, setAllScripts] = React.useState(false);
  const [allScriptDataFut, setAllScriptDataFut] = React.useState([]);
  const { addNotification } = useNotification();
  const [watchlistData, setWatchlistData] = React.useState([]);
  const [watchlistDataFilter, setWatchlistDataFilter] = React.useState([]);
  const [watchlistType, setWatchlistType] = React.useState("fut");

  React.useEffect(() => {
    var selected =
      localStorage.getItem("selectedWachlist") != ""
        ? localStorage.getItem("selectedWachlist")
        : "fut";

    setWatchlistType(selected);

    load_masters_watchlist();
  }, []);

  const search_script = (a) => {
    let script = [];

    if (a.length > 1) {
      script = watchlistDataFilter.filter((x) =>
        x.script_name.toLowerCase().includes(a.toLowerCase())
      );

      setWatchlistData(script);
    } else {
      // filter_script(watchlistType);
      setWatchlistData(watchlistDataFilter);
    }
  };

  const filter_script = (type) => {
    let script = [];

    script = watchlistDataFilter.filter((x) => x.script_type == type);
    setWatchlistData(script);
  };

  const load_masters_watchlist = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
      };

      php.load_masters_watchlist(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          let script = r.master_scripts.filter(
            (x) => x.script_type == watchlistType
          );

          setWatchlistDataFilter(r.master_scripts);
          // setWatchlistData(script);
          setWatchlistData(r.master_scripts);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const get_all_mcx_scripts = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
      };

      php.get_all_mcx_scripts(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setAllScripts(true);
          setAllScriptDataFut(r.mcx_script);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const get_all_fut_scripts = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
      };

      php.get_all_fut_scripts(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setAllScripts(true);
          setAllScriptDataFut(r.fut_script);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
        alignItems: "center",
      }}
    >
      <ScriptSearch
        // search={search}
        // add={add}
        // watclist_selected={watclist_selected}
        toWatchlist={() => {
          setAllScripts(false);
          load_masters_watchlist();
        }}
        get_all_fut_scripts={() => get_all_fut_scripts()}
        get_all_mcx_scripts={() => get_all_mcx_scripts()}
        loadWatchlist={() => load_masters_watchlist()}
        search_script={(a) => search_script(a)}
      />
    </div>
  );
  return (
    <div
      className="card"
      style={{
        paddingTop: 0,
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <TopbarMost name="Watchlist" cmp={filterbar} />
      <div
        style={{
          height: totalHeight - 60,
          overflowY: "scroll",
        }}
      >
        <TableHeader noDelete={allScripts} sorting={(a, b) => {}} />

        {allScripts == false ? (
          <div key={"sdhj"} className="scrolldivu">
            {loading
              ? "loading...."
              : watchlistData.length <= 0
              ? "No Data Found. Please Add Script First"
              : watchlistData.map((i, t) => {
                  return (
                    <p
                      key={i.id}
                      style={{ margin: 5, marginTop: 5, borderRadius: 0 }}
                    >
                      <WatchlistCard
                        key={i.id + new Date()}
                        item={i}
                        loadWatchlist={() => load_masters_watchlist()}
                      />
                    </p>
                  );
                })}
          </div>
        ) : (
          <div key={"sdhj"} className="scrolldivu">
            {loading
              ? "loading...."
              : allScriptDataFut.length <= 0
              ? "No Data Found. Please Add Script First"
              : allScriptDataFut.map((i, t) => {
                  return (
                    <p
                      key={i.id}
                      style={{ margin: 5, marginTop: 5, borderRadius: 0 }}
                    >
                      <WatchlistCard
                        key={i.id + new Date()}
                        item={i}
                        noDelete
                        loadWatchlist={() => load_masters_watchlist()}
                      />
                    </p>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
}
