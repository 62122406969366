import React from "react";
import styled, { css } from "styled-components";

import { Card } from "primereact/card";
import { CreateClientButton } from "../../Users/Overview/Styled";
import Php from "../../../Backend/Php";
import { NewTheme } from "../../Theme/Theme";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 250px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  border-color: ${NewTheme.MainColor};
  align-self: center;

  &:focus-visible {
    border-color: #6366f1;
    color: #ffffff;
    border-width: 3px;
    border: 1px solid #ffffff;
  }
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function Edit(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [formData, setFormData] = React.useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  const change_password = (e) => {
    if (
      formData?.password == "" ||
      formData?.password == undefined ||
      formData?.password == null
    ) {
      addNotification("Password field is empty", "error");
    } else if (
      formData?.old_password == "" ||
      formData?.old_password == undefined ||
      formData?.old_password == null
    ) {
      addNotification("Confirm Password field is empty", "error");
    } else if (
      formData?.confirm_password == "" ||
      formData?.confirm_password == undefined ||
      formData?.confirm_password == null
    ) {
      addNotification("Confirm Password field is empty", "error");
    } else if (formData?.confirm_password !== formData?.password) {
      addNotification("Password does not match", "error");
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          m_id: localStorage.getItem("mastersId"),
          form_data: formData,
        };

        php.change_password(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            logoutFunc();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      <Card
        style={{
          margin: 10,
          position: "relative",
          height: window.innerHeight - 160,
          overflowY: "scroll",
        }}
      >
        <div style={{ display: "flex" }}>
          <RightPanelContainer>
            <BalanceWrapper>
              <BalanceContainer>
                <BalanceLabel>Current Password</BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.old_password}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "old_password",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="Enter Old password "
                />
              </BalanceContainer>
              <BalanceContainer>
                <BalanceLabel>Password</BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.password}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "password",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="Enter password to change"
                />
              </BalanceContainer>

              <BalanceContainer>
                <BalanceLabel>Confirm Password</BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.confirm_password}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "confirm_password",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="Enter confirm password to match password"
                />
              </BalanceContainer>
              <BalanceUpdateButton onClick={change_password}>
                Change Password
              </BalanceUpdateButton>
            </BalanceWrapper>
          </RightPanelContainer>
        </div>
      </Card>
    </div>
  );
}
