import moment from "moment";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import UpdateNotification from "../../Common/Notification";

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin-right: 8px;
  &:checked {
    background-color: red; /* Change the background color to your desired color */
  }
`;

export const NotificationFilters = styled.div`
  label {
    margin-right: 16px;
  }
`;

const NotificationComponent = ({
  notificationList,
  notificationTypes,
  load_notification,
}) => {
  const initialFilters =
    JSON.parse(localStorage.getItem("selectedFilters")) || [];
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  React.useEffect(() => {
    // Save selectedFilters to localStorage whenever it changes
    localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
  }, [selectedFilters]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };
  const filteredList =
    selectedFilters.length === 0
      ? notificationList
      : notificationList.filter((notification) =>
          selectedFilters.includes(notification.notification_type)
        );

  const groupedNotificationTypes = [];
  for (let i = 0; i < notificationTypes.length; i += 2) {
    groupedNotificationTypes.push(notificationTypes.slice(i, i + 2));
  }

  return (
    <div>
      <UpdateNotification update={() => load_notification()} />
      <NotificationFilters>
        {groupedNotificationTypes.map((typePair, index) => (
          <div key={index} style={{ display: "flex", marginBottom: 10 }}>
            {typePair.map((type) => (
              <div key={type} style={{ marginRight: 20 }}>
                <label style={{ fontWeight: "bold" }}>
                  <input
                    type="checkbox"
                    value={type}
                    checked={selectedFilters.includes(type)}
                    onChange={handleCheckboxChange}
                  />
                  {type}
                </label>
              </div>
            ))}
          </div>
        ))}
      </NotificationFilters>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          padding: "5px 0px 5px 0px",
          padding: 5,
          borderColor: NewTheme.MainColor,
          fontWeight: "bold",
        }}
      >
        {filteredList && filteredList.length != 0 ? (
          filteredList.map((i, t) => (
            <div
              class={
                i.msg_type == 0
                  ? "p-inline-message p-component p-inline-message-success"
                  : i.msg_type == 1
                  ? "p-inline-message p-component p-inline-message-error"
                  : i.msg_type == 2
                  ? "p-inline-message p-component p-inline-message-info"
                  : "p-inline-message p-component p-inline-message-warn"
              }
              style={{
                width: "100%",
                justifyContent: "unset",
                padding: 5,
                position: "relative",
                borderWidth: 0.5,
                textAlign: "left",
                margin: 5,
              }}
            >
              <div>
                <div style={{ fontSize: 12, fontWeight: "bolder" }}>
                  {i.notification_type}
                </div>
                <div style={{ fontSize: 14 }}>{i.msg}</div>
                <div
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    fontSize: 12,
                    fontWeight: "bolder",
                  }}
                >
                  {moment(i.date_created).format("DD-MMM H:m:s")}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No Data Found</div>
        )}
      </div>
    </div>
  );
};

export default NotificationComponent;
