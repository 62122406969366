import React from "react";
import { NumericFormat } from "react-number-format";

export default function NumberFormatIn(props) {
  return (
    <NumericFormat
      value={parseFloat(props.value).toFixed(props.fixed ? props.fixed : 0)}
      displayType={"text"}
      thousandSeparator={true}
      thousandsGroupStyle="lakh"
    />
  );
}
