import React from "react";
import { NewTheme } from "../../Theme/Theme";
import { Message } from "primereact/message";
import UpdateUsers from "../../Common/UpdateUsers";

export default function UsersComponent({ onlineUsersData, reload }) {
  return (
    <div>
      <UpdateUsers update={reload} />
      <div
        className="card"
        style={{ height: window.innerHeight - 180, overflowY: "scroll" }}
      >
        <div
          style={{
            display: "flex",
            padding: "5px 0px 5px 0px",
            padding: 5,
            flexDirection: "column",
            flexWrap: "wrap",
            borderColor: NewTheme.MainColor,
            fontWeight: "bold",
          }}
        >
          {onlineUsersData && onlineUsersData.length != 0
            ? onlineUsersData.map((i, t) => (
                <>
                  <Message
                    style={{
                      width: "90%",
                      margin: 5,
                      justifyContent: "flex-start",
                      display: "flex",
                    }}
                    severity="success"
                    text={i.username}
                  />
                </>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}
